<template>
  <entity-card
    :tabs="tabs"
    :tabs-items="tabsItems"
    :title="item.name"
    :image="image"
    :actions="actions"
    :show-back-btn="canBack"
    :loading="loading"
    @click:back="handleBack"
    @click:close="handleClose"
  >
    <template #header:status-bar>
      <div class="mr-2" :class="{ 'red--text': !item.enabled }">
        {{ item.enabled ? 'Enabled' : 'Disabled' }}
      </div>
    </template>
  </entity-card>
</template>

<script>
import EntityCard from '@/components/entity-card';
import {
  computed,
  onBeforeMount,
  onMounted,
  provide,
  ref,
  toRefs,
  watch
} from '@vue/composition-api';

import { useTypeMenu } from '@/modules/object-types/compositions/typeMenu';

import { useType } from '@/modules/object-types/compositions/type';
import useHistoryStack from '@/compositions/history-stack';

export default {
  name: 'TypeCard',
  components: {
    EntityCard
  },
  props: {
    typeId: {
      type: String,
      required: true
    }
  },
  setup(props, { root, emit }) {
    const { typeId } = toRefs(props);

    const { item, load, loading } = useType(typeId);
    const { hasPrevRoute: canBack } = useHistoryStack();

    onBeforeMount(async () => {
      await load();
    });

    const handleBack = () => {
      emit('stack:back');
    };

    const image = computed(() => item.value.url || '/img/object.png');

    provide('entity', item);
    provide('entityId', props.typeId);

    const tabs = [
      {
        id: 'general',
        title: 'General'
      }
      // {
      //   id: 'access',
      //   title: 'Access'
      // }
    ];
    const tabsItems = [
      () => import('./general/index')
      // () => import('./access/index')
    ];

    const itemActions = computed(() => useTypeMenu({ entity: item.value }));

    const handleClose = () => {
      const { typeId, ...params } = root.$route.params;
      const { st, ...query } = root.$route.query;
      root.$router.push({
        name: 'types',
        params,
        query
      });
    };

    return {
      tabs,
      image,
      actions: itemActions,
      handleClose,
      tabsItems,
      loading,
      item,
      canBack,
      handleBack
    };
  }
};
</script>
