import typeSchema from '../api/type.graphql';
import { useLazyQuery, useResult } from '@vue/apollo-composable';
import { trimName } from '@/modules/object-types/api/type';
import { SUB_EVENT_TYPES } from '@/provider/utils';

export function useType(typeId) {
  const { result, load, loading, subscribeToMore } = useLazyQuery(
    typeSchema.fetch,
    {
      id: typeId
    },
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-only'
    }
  );

  const item = useResult(result, {}, data => {
    return trimName(data.schema);
  });

  subscribeToMore({
    document: typeSchema.listenType,
    variables: {
      id: typeId
    },
    updateQuery: (previousResult, { subscriptionData }) => {
      const relatedNode = subscriptionData.data?.Schemas?.relatedNode;
      const eventType = subscriptionData.data?.Schemas?.event;
      if (eventType !== SUB_EVENT_TYPES.insert) return;
      if (!relatedNode) return;
      switch (relatedNode.__typename) {
        case 'SchemaProperty': {
          return {
            schema: {
              ...previousResult.schema,
              schemaProperties: [
                ...previousResult.schema.schemaProperties,
                relatedNode
              ]
            }
          };
        }
      }
    }
  });

  return {
    load,
    item,
    loading
  };
}
